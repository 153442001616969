import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import initialize from './init/store';

const main = async (): Promise<void> => {
	const eduCTXstore = await initialize();
	ReactDOM.render(
		<Provider store={eduCTXstore}>
			<App />
		</Provider>,
		document.getElementById('root')
	);

	serviceWorker.unregister();
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

main();
